var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{attrs:{"fluid":""}},[_c('Navbar',{attrs:{"navbarTitle":"Manage ODS","primaryBtnText":_vm.editEnabled ? 'Submit' : 'Edit'},on:{"primaryEvent":function($event){return _vm.saveRegionsForZoneODS()}}}),[_c('div',{staticClass:"d-flex flex-row"},[_c('div',{staticClass:"mt-4 mb-4 ml-1-5",staticStyle:{"width":"350px"}},[_c('v-select',{attrs:{"placeholder":"Select Zone","rules":[function (v) { return !!v || 'Required'; }],"return-object":"","items":_vm.zonesOfCompany,"item-value":"id","item-text":"name","menu-props":{
              bottom: true,
              offsetY: true,
              maxHeight: 200,
              width: _vm.max - _vm.content,
            }},on:{"change":_vm.changeZoneFilter},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"red--text"},[_vm._v("* ")]),_vm._v("Select Zone ")]},proxy:true}]),model:{value:(this.selectedZone),callback:function ($$v) {_vm.$set(this, "selectedZone", $$v)},expression:"this.selectedZone"}})],1),_c('v-container',[_c('v-row',{staticClass:"mt-n3"},[_c('searchable-list',{attrs:{"items":_vm.regionsForZoneSelected,"card-title":"Region List","key-for-item":"id","title-property":"name","item-prepend-icon":"mdi-plus","no-items-message":_vm.noitemMessage},on:{"selectItem":_vm.selectRegion},scopedSlots:_vm._u([{key:"itemSubTitle",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.regionName !== null ? item.regionName : "No Regions")+" ")]}},{key:"noOfItems",fn:function(){return [_vm._v(" "+_vm._s(_vm.regionCount)+" items ")]},proxy:true}])}),_c('searchable-list',{attrs:{"items":_vm.selectedRegions,"card-title":"Assigned Regions","uses-search-bar":"false","key-for-item":"id","title-property":"name","item-prepend-icon":"mdi-close","icon-hover-color":"red"},on:{"selectItem":_vm.deselectRegion},scopedSlots:_vm._u([{key:"itemSubTitle",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.regionName !== null ? item.regionName : "No Region")+" ")]}}])})],1)],1)],1)]],2),_c('Snackbar',{attrs:{"message":_vm.message,"snackbar":_vm.snackbar,"timeout":5000,"color":_vm.snackbarColor},on:{"update:snackbar":function($event){_vm.snackbar=$event},"closeSnackbar":_vm.closeSnackbar}}),_c('Popup',{attrs:{"popupDialog":_vm.warningPopup,"title":"Warning!","subtitle":"For Deactivation - detach User, Beat and Distributor","text":_vm.warningPopupMsg},on:{"close":function($event){_vm.warningPopup = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }