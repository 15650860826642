























































































import { Component, Vue } from "vue-property-decorator";
import DropDown from "@/components/common/Inputs/DropDown.vue";
import Navbar from "@/components/common/Navbar.vue";
import Illustration from "@/components/common/Illustration.vue";
import Snackbar from "@/components/common/Snackbar.vue";
import GeographyService from "./../../services/GeographyService";
import SearchableList from "@/components/common/SearchableList.vue";
import Popup from "@/components/common/Popup/Popup.vue";
import TextButton from "@/components/common/Buttons/TextButton.vue";
import { IEntityMinWithParent, IRegion } from "@/interfaces/positioncode";

@Component({
  components: {
    Illustration,
    Navbar,
    Snackbar,
    DropDown,
    SearchableList,
    Popup,
    TextButton,
  },
})
export default class Create extends Vue {
  private message: string = "";
  private snackbar: boolean = false;
  private editEnabled: boolean = false;
  private snackbarColor: string = "success";
  private noitemMessage: string = "Please Select a Zone";
  private selectedZone: number = 0;
  private regionsOfCompanyOriginal: Array<IEntityMinWithParent> = [];
  private zonesOfCompanyOriginal: Array<IEntityMinWithParent> = [];
  private regionsOfCompany: Array<any> = [];
  private zonesOfCompany: Array<any> = [];
  private regionsForZoneSelected: Array<IRegion> = [];
  private selectedRegions: Array<IRegion> = [];
  public IsMT: boolean = false;
  private regionCount: number = 0;
  public showCommunicationError(err: any) {
    this.snackbarColor = "red";
    this.snackbar = true;
    this.message = window.navigator.onLine
      ? `Some Error occured`
      : `Cannot reach server at the moment`;
  }

  public closeSnackbar(value: boolean): void {
    this.snackbar = value;
  }
  public async getRegions(): Promise<void> {
    GeographyService.getAllGeographiesOfType("region")
      .then((res) => {
        debugger;
        this.regionsOfCompanyOriginal = res.data;
        this.regionsOfCompany = this.regionsOfCompanyOriginal;
      })
      .catch((err) => {
        this.showCommunicationError(err);
        console.log(err);
      });
  }
  public async getZones(): Promise<void> {
    GeographyService.getCompanyZones()
      .then((res) => {
        this.zonesOfCompanyOriginal = res.data;
        this.zonesOfCompany = this.zonesOfCompanyOriginal;
        debugger;
      })
      .catch((err) => {
        this.showCommunicationError(err);
        console.log(err);
      });
  }

  public changeZoneFilter(zone: IRegion) {
    debugger;
    this.regionsForZoneSelected = [];
    this.selectedRegions = [];
    this.selectedZone = zone.id;
    this.noitemMessage = "No More Active Regions in Selected Zone";
    this.GetSelectedRegionsForZoneODS(zone.id);
    this.GetRegionsForZone(zone.id);
  }
  private async GetRegionsForZone(zoneid: number) {
    debugger;
    const totalRegionForZoneCount = this.regionsOfCompanyOriginal.filter(
      (elem) => {
        if (elem.parentId == zoneid) return elem;
      }
    ).length;

    debugger;
    GeographyService.getAllGeographiesOfType("region")
      .then((res) => {
        this.regionsForZoneSelected = res.data;
        this.regionsForZoneSelected = this.regionsForZoneSelected.filter(
          (elem) => {
            if (elem.parentId == zoneid) return elem;
          }
        );
        this.regionCount =
          totalRegionForZoneCount -
          this.regionsForZoneSelected.filter((b) =>
            this.selectedRegions.find((sb) => sb.id === b.id)
          ).length;
        this.regionsForZoneSelected =
          this.selectedRegions.length > 0
            ? this.regionsForZoneSelected.filter(
                (b) => !this.selectedRegions.find((sb) => sb.id === b.id)
              )
            : this.regionsForZoneSelected;
        this.regionsForZoneSelected.sort((a, b) =>
          a.name.localeCompare(b.name)
        );
      })
      .catch((e) => {
        this.showCommunicationError(e);
        console.log(e);
      });
  }
  private async GetSelectedRegionsForZoneODS(zoneid: number) {
    debugger;
    GeographyService.getODSRegionsForZone(zoneid)
      .then((res) => {
        this.selectedRegions = res.data;
        debugger;
      })
      .catch((e) => {
        console.log(e);
      });
  }
  public selectRegion(index: number) {
    debugger;
    if (this.editEnabled) {
      this.selectedRegions.push(this.regionsForZoneSelected[index]);
      this.regionsForZoneSelected.splice(index, 1);
      this.regionCount--;
    } else {
      this.snackbarColor = "red";
      this.snackbar = true;
      this.message = "Please enable edit";
    }
  }
  public deselectRegion(index: number) {
    debugger;
    if (this.editEnabled) {
      let r = this.selectedRegions[index];
      let i: number;
      let distributorNameLC = r.name.toLowerCase();
      for (i = 0; i < this.regionsForZoneSelected.length; i++) {
        if (
          this.regionsForZoneSelected[i].name.toLowerCase() > distributorNameLC
        ) {
          this.regionsForZoneSelected.splice(i, 0, r);
          break;
        }
      }
      if (i == this.regionsForZoneSelected.length)
        this.regionsForZoneSelected.splice(i, 0, r);
      this.selectedRegions.splice(index, 1);
      this.regionCount++;
    } else {
      this.snackbarColor = "red";
      this.snackbar = true;
      this.message = "Please enable edit";
    }
  }
  public saveRegionsForZoneODS() {
    debugger;
    if (this.editEnabled) {
      if (this.selectedZone == 0) {
        this.snackbarColor = "red";
        this.snackbar = true;
        this.message = "Please select a zone";
        return;
      }
      GeographyService.saveRegionForODS(
        this.selectedZone,
        this.selectedRegions.map((b) => b.id)
      )
        .then((res) => {
          this.snackbarColor = "success";
          this.snackbar = true;
          this.message = `Changes Saved Successfully`;
        })
        .catch((e) => {
          this.showCommunicationError(e);
        });
    }
    this.editEnabled = !this.editEnabled;
  }
  async created() {
    this.getRegions().then((_) => _);
    this.getZones().then((_) => _);
  }
  mounted() {}
}
